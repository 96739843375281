body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Montserrat',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    height:'100vh';
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,800,900');
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New','Montserrat',
    monospace;
}
