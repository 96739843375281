.App {
  text-align: center;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .label .text-wrapper {
  -webkit-text-stroke: 1px #FFF;
  color: transparent;
  font-family: "Montserrat-Regular", Helvetica;
  font-size: 6rem;
  font-weight: 400;
  line-height: normal;

} */


.formImage {
  animation: zoom 5s infinite;
  max-inline-size: 100%;
  block-size: auto
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
